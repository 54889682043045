@import url('https://fonts.googleapis.com/css2?family=Crimson+Pro:ital,wght@0,200;0,400;0,700;1,400&display=swap');

@media screen and (min-width: 600px) {
    :root {
        font-size: 600px;
    }
}

@media screen and (max-width: 600px) and (min-width: 400px) {
    :root {
        font-size: 100vw;
    }
}

@media screen and (max-width: 400px) {
    :root {
        font-size: 400px;
    }
}

:root {
    height: 100%;
}

#root {
    height: 100%;
}

body {
    box-sizing: border-box;
    margin: 0;
    overflow: hidden;
    font-family: 'Crimson Pro';
    height: 100%;
}

.spinner-circle {
    border: 3px solid #1B0E25;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}